@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@1,1000&family=Poppins:wght@300&family=Raleway:wght@600&family=Roboto:wght@400&family=Rubik:wght@500&family=Montserrat:wght@400;500;600;800&family=Karla:wght@300;500&family=Oswald:wght@200;400;700&display=swap');

@font-face {
    font-family: 'STIHL-medium';
    src: url('../utils/Fonts/STIHLContrafaceDisplayMedium.otf') format(opentype);
}

@font-face {
    font-family: 'STIHL-titling';
    src: url('../utils/Fonts/STIHLContrafaceDisplayTitling.otf') format(opentype);
}

@font-face {
    font-family: 'STIHL-text';
    src: url('../utils/Fonts/STIHLContrafaceText.otf') format(opentype);
}

@font-face {
    font-family: 'STIHL-text-bold';
    src: url('../utils/Fonts/STIHLContrafaceText-Bold.otf') format(opentype);
}

@font-face {
    font-family: 'STIHL-text-dem';
    src: url('../utils/Fonts/STIHLContrafaceTextDemi.otf') format(opentype);
}

@font-face {
    font-family: 'STIHL-text-light';
    src: url('../utils/Fonts/STIHLContrafaceTextLight.otf') format(opentype);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


:root {
    --mainColor: rgb(243, 122, 31);
    --mainColorLight: #ffb326;
    --secondaryColor: #EEE;
    --textColor: #000000;
    --MidGreenColor: #35852e;
    --bgColor: #4b4b4b;
}

nav {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 70;
}

nav .mainMenu li {
    display: inline-block;
    letter-spacing: 2px;
    padding: 45px 45px;
    text-transform: uppercase;
}

nav .mainMenu li a {
    font-family: 'STIHL-text-dem', sans-serif;
    font-size: 24px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    transition: 0.2s ease;
}

nav .mainMenu li a:hover {
    color: rgba(68, 68, 68, 0.945)
}

nav .openMenu {
    font-size: 2rem;
    display: none;
    padding: 35px 30px;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

nav .mainMenu .closeMenu {
    font-size: 2rem;
    display: none;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

nav .mainMenu .closeMenu,
.icons svg {
    padding: 20px 40px;
    font-size: 2rem;
    display: none;
    cursor: pointer;
}

.logo img {
    width: 18vh;
    margin-left: 20px;
}

.fa-facebook:hover {
    color: rgb(13, 63, 128);
}

.fa-instagram:hover {
    color: rgb(255, 255, 255);
}

.fa-whatsapp:hover {
    color: #119943;
}

.container-img {
    min-height: 110vh;
    display: flex;
    align-items: center;
    background: #111;
}

.container-img img {
    position: absolute;
    top: 0;
    background-size: cover;
    background-position: center;
    left: 0;
    width: 100%;
    height: 110vh;
    object-fit: cover;
    opacity: 0.8;
}

.bg-img {
    filter: blur(2px);
    min-height: 110vh;
}

.container-text {
    z-index: 1;
    margin-left: 10%;
}

.container-text h2 {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 4.3em;
    line-height: 1.4em;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: var(--mainColor);
}

.container-text p {
    font-family: 'STIHL-text-bold', sans-serif;
    font-size: 2em;
    color: #EEE;
    margin: 20px 0;
    letter-spacing: 1px;
    text-align: center;
}

.logos {
    overflow: hidden;
    padding: 21px 0;
    background-color: var(--bgColor);
    white-space: nowrap;
    position: relative;
}

.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos-slide {
    display: inline-block;
    animation: 43s slide infinite linear;
}

.logos-slide img {
    height: 40px;
    margin: 0 40px;
}

.featured-h2 {
    font-family: 'STIHL-text-dem', sans-serif;
    font-weight: 600;
    padding: 40px;
    text-transform: uppercase;
    color: var(--mainColor);
    text-decoration: underline;
    letter-spacing: 3px;
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
}

.grid-wrapper {
    background-color: white;
    width: 100%;
}

.grid-layout {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    width: 70%;
    height: 100%;
    margin: auto;
    padding: 25px;
    align-items: center;
    grid-gap: 50px;
    grid-column-gap: 20%;
}

.box {
    background-color: white;
    min-width: 100%;
    height: auto;
}

.title-featured {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 20px;
    text-decoration: none;
    color: var(--mainColor);
}

.p-featured {
    font-family: 'STIHL-text', sans-serif;
    font-size: 21px;
}

.catalogue {
    font-family: 'STIHL-text', sans-serif;
    font-size: 25px;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.catalogue-btn-container {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.catalogue-btn {
    font-family: 'STIHL-medium', sans-serif;
    font-size: 17px;
    background-color: var(--mainColor);
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0 auto;
    border-radius: 8px;
    transition-duration: 0.4s;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.catalogue-btn:hover {
    background-color: #000000;
}

.grid-container {
    background-color: var(--mainColor);
    padding: 5em;
}

.wrapper-imgs {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    gap: 10px;
}

.grid-item {
    display: flex;
    flex-wrap: wrap;
}

.grid-item img {
    width: 60%;
    height: auto;
    margin: 6px auto;
    border-radius: 20px;
    background-color: var(--mainColor);
    opacity: 0.6;
}

.image-first-column {
    position: relative;
    left: 19%;
}

.image-second-column {
    position: relative;
    right: 20%;
}

.carousel-container {
    background-color: var(--mainColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.tips-stihl {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 30px;
    text-decoration: underline;
    margin-bottom: 50px;
}

.carousel-wrapper {
    width: 100%;
    overflow: hidden;
    margin-bottom: 40px;
}

.slick-slider {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.slick-list {
    width: 80%;
}

.slider-img {
    position: relative;
    border-radius: 2vh;
    width: 80%;
}


.custom-prev-arrow,
.custom-next-arrow {
    top: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.custom-prev-arrow {
    left: 20vh;
}

.custom-next-arrow {
    right: 20vh;
}

.custom-prev-arrow:hover,
.custom-next-arrow:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.slide {
    display: flex !important;
    justify-content: center !important;
    position: relative;
}

.overlay {
    border-radius: 2vh;
    position: absolute;
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    width: 80%;
    height: 0;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    opacity: 0;
    transition: height 0.6s, opacity 0.6s;
    margin-left: 10%;
    overflow-y: hidden;
}

.slide:hover .overlay {
    height: 100%;
    opacity: 1;
}

.carousel-hover-text {
    font-family: 'STIHL-text-light', sans-serif;
    font-weight: 300;
    letter-spacing: 0.8px;
    text-align: center;
    font-size: 17px;
    margin: 10px;
    opacity: 0;
    transition: opacity 0.2s;
    transition-delay: 0.3s;
}

.slide:hover .carousel-hover-text {
    opacity: 1;
}

.stihl-link {
    color: var(--mainColor);
}

.footer {
    background-color: #1f1e1e;
    min-height: 62vh;
}

.logo-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-footer img {
    width: 25vh;
}

.links-footer {
    display: flex;
    list-style: none;
    justify-content: center;
    margin-top: 30px;
    gap: 40px;
}

.links-footer li a {
    font-family: 'STIHL-text-bold', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.5s, font-size 0.5s;
}

.links-footer li a:hover {
    color: var(--mainColor);
    font-size: 19px;
}

.line-through {
    width: 100%;
    border-bottom: 1px solid #979090;
    margin-top: 45px;
}

.contact-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    gap: 50px;
    margin-top: 40px;
    color: #ffffff;
}

.icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-contact {
    font-size: 25px;
}

.text-icons {
    font-family: 'STIHL-text-light', sans-serif;
    font-size: 19px;
    margin: 0 20px;
}

.text-number {
    font-family: 'STIHL-text-light', sans-serif;
    font-size: 20px;
    margin: 0 20px;
}

.social-network {
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    gap: 60px;
    text-decoration: none;
    margin-top: 70px;
}

.social-icons {
    font-size: 30px;
    color: #ffffff;
}

.instagram:hover {
    color: var(--mainColor);
}

.faq-background {
    background-image: url('../images/woodbg.jpg');
    background-size: cover;
    width: 100%;
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.faq-container {
    background-color: rgb(248, 223, 182);
    width: 50%;
    border-radius: 15px;
    background-image: url('../images/stihl-animated.jpg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: -5px, -70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 40px;
}

.question {
    width: 50%;
    padding: 14px;
}

.question-container {
    border-bottom: solid 1px #8f8c8c;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
}

.question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-header h4 {
    font-family: 'STIHL-text', sans-serif;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
    overflow: hidden;
}

.question-header h4:hover {
    color: var(--mainColor);
}

.question-header .btn {
    margin-left: 10px;
    color: var(--mainColor);
}

.question-header .btn:hover {
    color: var(--mainColor);
}

.question.expanded .question-container {
    max-height: 500px;
    transition: max-height 0.5s ease-in-out;
}

.answer-text {
    font-family: 'STIHL-text-light', sans-serif;
    font-size: 17px;
    line-height: 1.5;
    color: #585757;
}

.contact-container-faq {
    font-family: 'STIHL-text-dem', sans-serif;
    font-size: 18px;
    position: relative;
    display: flex;
    align-self: flex-start;
    text-align: center;
    width: 75%;
    top: 8%;
    margin: 0;
    padding: 0;
}

.contact-icons-faq {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-icons-faq a,
.contact-icons-faq span {
    margin: 0 5px;
    display: inline-block;
}

.icon-container {
    display: flex;
    align-items: center;
}

.icons-container-faq {
    display: flex;
    align-items: center;
}

.icons-faq {
    font-size: 25px;
    color: var(--mainColor);
    display: inline-block;
}

.wp-faq {
    font-size: 38px;
    margin-top: 5px;
}

.mbl {
    font-size: 24px;
    color: var(--mainColor);
    display: inline-block;
    margin-left: 0;
    position: relative;
    cursor: pointer;
    margin-top: 2px;
    margin-right: 5px;
}

.popup {
    font-family: 'STIHL-text-dem', sans-serif;
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: none;
    font-size: 18px;
    margin: 0;
}

.popup p {
    margin: 0;
}

.popup:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    transform: translateX(-50%);
}

.mbl:hover .popup {
    display: block;
}

.text-number-faq {
    font-family: 'STIHL-text-dem', sans-serif;
    font-size: 18px;
    margin-left: 10px;
}

.instagram-faq:hover {
    color: #000000;
}

.main-container-products {
    background-color: #f4f4f4;
}

.animated-header {
    height: 60vh;
    background-color: var(--mainColor);
    display: flex;
    justify-content: center;
    align-items: center;
}

.animated-imgs {
    height: auto;
    position: absolute;
    margin-top: 90px;
}

.ms-animated {
    height: auto;
    width: 28%;
    margin-top: 70px;
}

.fs-animated {
    height: auto;
    width: 15%;
    margin-right: 65%;
}

.axe-animated {
    height: auto;
    width: 15%;
    margin-left: 65%;
}

.search-bar-container {
    display: flex;
    position: absolute;
    justify-content: center;
    height: 6vh;
    margin: 0;
    top: 53%;
}

.search_bar .search_box {
    position: relative;
    width: 55vh;
    height: 6vh;
}

.search_bar .search_box .input-bar {
    font-family: 'STIHL-text-light', sans-serif;
    font-size: 1.2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 0;
    outline: none;
}

.input-bar::placeholder {
    width: 14em;
}

.search_bar .search_box .btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    background: #0e0d0d;
    z-index: 1;
    cursor: pointer;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.search-cleaner {
    position: absolute;
    top: 53%;
    left: 85%;
    transform: translate(-50%, -50%);
    color: rgb(184, 184, 184);
    font-size: 1.4rem;
    cursor: pointer;
}

.search-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.1rem;
}

.search-cleaner:hover {
    color: rgb(12, 12, 12);
    transition: color 0.5s ease;
}

.filter-button {
    display: none;
}

.loading-products {
    font-family: 'STIHL-text-dem', sans-serif;
    font-size: 25px;
    margin-left: 30%;
}

.categories-brands-list {
    font-family: 'STIHL-medium', sans-serif;
    padding: 35px;
    width: 400px;
    font-size: 16px;
}

.categories-brands-list h2 {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
}

.categories-brands-list ul {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.categories-brands-list.expanded ul {
    max-height: 200px;
}

.categories-brands-list li {
    margin-bottom: 10px;
}

.categories-brands-list a {
    text-decoration: none;
    color: #333;
    display: block;
    padding: 5px;
    transition: background-color 0.2s ease-in-out;
}

.categories-brands-list a:hover {
    background-color: #ddd;
    border-radius: 5px;
    color: #222;
}

.categories-brands-list a.active {
    font-weight: bold;
    color: var(--mainColor);
    background-color: #ddd;
    border-radius: 5px;

}

.filter-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
}

.filter-title:hover {
    color: var(--mainColor);
}

.filter-title-icon {
    font-size: 16px;
    margin-left: 5px;
}

.filters-products-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: auto;
    min-height: 100vh;
    padding: 20px;
}

.filters-msg {
    font-family: 'STIHL-text-bold', sans-serif;
    text-transform: uppercase;
    font-size: 18px;
}

.loading-spinner-container {
    width: 100%;
    text-align: center;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid var(--mainColor);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
}

.wrap-products {
    background-color: #f4f4f4;
}

.products-grid {
    width: 75%;
    padding: 30px;
}

.product-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ccc;
    margin: 5px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
    text-align: center;
    width: 24%;
    box-sizing: border-box;
    background-color: white;
    height: 500px;
}

.product-image {
    max-width: 100%;
    height: 31.50vh;
}

.product-name {
    font-family: 'STIHL-text-bold', sans-serif;
    font-size: 18px;
    margin-top: 10px;
}

.details-product-button {
    font-family: 'STIHL-medium', sans-serif;
    font-size: 15px;
    background-color: var(--mainColor);
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    transition-duration: 0.4s;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.details-product-button:hover {
    background-color: var(--textColor);
}

.load-more-button {
    font-family: 'STIHL-medium', sans-serif;
    font-size: 15px;
    background-color: #1f1e1e;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 20px;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.load-more-button:hover {
    background-color: var(--mainColor);
}

.product-general-background {
    min-height: 100vh;
    background-color: var(--secondaryColor);
}

.product-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--mainColor);
    height: 100vh;
    flex-direction: column;
}

.product-details-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    background-color: #ffffff;
    width: 1200px;
    height: 70vh;
    margin-top: 70px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
}

.return-link-container {
    position: absolute;
    top: 20px;
    left: 20px;
}

.return-link {
    font-family: 'STIHL-text-bold', sans-serif;
    text-decoration: none;
    color: #000000;
    font-size: 18px;
}

.return-icon {
    font-size: 15px;
    margin-right: 5px;
}

.return-link:hover {
    text-decoration: underline;
}

.product-image-detail {
    max-width: 40%;
    height: 45vh;
    margin: 40px 40px;
}

.product-info {
    flex: 1;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.product-name-detail {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 2em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 35px;
}

.product-description-detail {
    font-family: 'STIHL-text-light', sans-serif;
    font-weight: 300;
    font-size: 20px;
    width: 600px;
    margin-bottom: 30px;
}

.spec-product {
    font-family: 'STIHL-text-bold', sans-serif;
    font-size: 18px;
    color: #000000;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 20px;
}

.details-single-product-button {
    font-family: 'STIHL-medium', sans-serif;
    font-size: 15px;
    background-color: var(--mainColor);
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    transition-duration: 0.4s;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.details-single-product-button:hover {
    background-color: var(--textColor);
}

.img-disclaimer {
    font-family: 'STIHL-text-light', sans-serif;
    margin-top: 25px;
    font-size: 15px;
    width: 500px;
}

.product-specification-container {
    display: flex;
    justify-content: center;
    margin: 150px;
}

.product-description-wrapper {
    font-family: 'STIHL-text', sans-serif;
    font-size: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    background-color: #ffffff;
    width: 1200px;
    margin: 0 auto;
    padding: 2.7rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px #d1d1d1;
}

.product-specification-wrapper {
    font-family: 'STIHL-text', sans-serif;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    padding: 2.7rem;
    background-color: #ffffff;
    width: 900px;
    border-radius: 10px;
    margin: 0 40px;
    box-shadow: 0 3px 6px #d1d1d1;
}

.full-description {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.product-specification {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.product-spec-tb {
    width: 100%;
    border: none;
    margin-bottom: 0;
}

.table tr {
    border: 1px solid #ddd;
}

.gray-row {
    background-color: #f8f8f8;
}

.spec-name {
    text-align: left;
    padding: 13px;
}

.spec-value {
    text-align: right;
    padding: 13px;
}

.related-products-container {
    display: flex;
    justify-content: center;
    margin: 150px;
}

.related-products-wrapper {
    background-color: #ffffff;
    margin: 0;
    padding: 2.7rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px #d1d1d1;
}

.related-products-title {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 26px;
}

.related-products-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ccc;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
    text-align: center;
    width: calc(24% - 20px);
    box-sizing: border-box;
    background-color: white;
    height: 500px;
}

.related-products-cards {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 50px;
}

.contact-product-container {
    background-color: var(--mainColor);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-product-wrapper {
    font-family: 'STIHL-text', sans-serif;
    font-size: 17px;
    background-color: #ffffff;
    width: 700px;
    max-height: 95vh;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
}

.contact-title {
    font-family: 'STIHL-titling', sans-serif;
    font-size: 26px;
    margin-bottom: 20px;
}

.contact-subtitle {
    font-family: "STIHL-text-light", sans-serif;
    font-size: 20px;
    margin-bottom: 30px;
}

.contact-form {
    font-family: 'STIHL-text', sans-serif;
    font-size: 18px;
    display: flex;
    flex-direction: column;
}

.contact-label {
    margin-bottom: 8px;
}

.contact-input {
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #afafaf;
    border-radius: 6px;
}

.contact-textarea {
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #afafaf;
    border-radius: 6px;
    resize: none;
    height: 150px;
}

.contact-submit-button {
    font-family: 'STIHL-medium', sans-serif;
    font-size: 15px;
    background-color: var(--mainColor);
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    margin-top: 25px;
    margin-bottom: 20px;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.contact-submit-button:hover {
    background-color: var(--textColor);
}

.contact-form .contact-input::placeholder,
.contact-form .contact-textarea::placeholder {
    font-family: "STIHL-text-light", sans-serif;
    font-size: 18px;
}

.social-contact-form {
    font-family: "STIHL-text-light", sans-serif;
    font-size: 20px;
    color: #0e0d0d;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: -30px;
}

.social-contact-form li {
    list-style: none;
}

.social-contact-form li a {
    display: inline-block;
    margin-right: 20px;
}

.fb-icon {
    color: #000000;
    font-size: 30px;
}

.ig-icon {
    color: #000000;
    font-size: 30px;
}

.wp-icon {
    color: #000000;
    font-size: 30px;
}

.ig-icon:hover {
    color: var(--mainColor);
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@media (1580px <=width <=1780px) {
    .carousel-hover-text {
        font-family: 'STIHL-text-light', sans-serif;
        font-weight: 300;
        letter-spacing: 0.8px;
        text-align: center;
        font-size: 15px;
        margin: 10px;
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.3s;
    }

    .footer {
        background-color: #1f1e1e;
        padding: 20px;
    }

    .faq-container {
        background-color: rgb(248, 223, 182);
        width: 60%;
        border-radius: 15px;
        background-image: url('../images/stihl-animated.jpg');
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: -5px, -70px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 20px;
    }

    .contact-container-faq {
        font-family: 'STIHL-text-dem', sans-serif;
        font-size: 18px;
        position: relative;
        display: flex;
        align-self: flex-start;
        text-align: center;
        width: 75%;
        top: 8%;
        margin: 0;
        padding: 0;
    }

    .search-bar-container {
        display: flex;
        position: absolute;
        justify-content: center;
        height: 6vh;
        margin: 0;
        top: 53%;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 1rem;
    }

    .search_bar .search_box .btn {
        width: 50px;
        height: 100%;
    }

    .fa-xmark-bar {
        font-size: 1.2rem;
        margin-right: 0.1em;
        margin-bottom: 0.1rem;
    }

    .categories-brands-list {
        font-family: 'STIHL-medium', sans-serif;
        padding: 35px;
        width: 350px;
        font-size: 16px;
    }

    .contact-product-wrapper {
        font-family: 'STIHL-text', sans-serif;
        font-size: 17px;
        background-color: #ffffff;
        width: 700px;
        border-radius: 10px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .contact-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 24px;
        margin-bottom: none;
    }

    .contact-subtitle {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 20px;
        margin-bottom: 20px;
        height: 10px;
    }

    .contact-form {
        font-family: 'STIHL-text', sans-serif;
        font-size: 15px;
        margin: auto;
        display: flex;
        flex-direction: column;
	height: 75vh;
    }

    .contact-label {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .contact-input {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #afafaf;
        border-radius: 6px;
    }

    .contact-textarea {
        padding: 5px;
        margin-bottom: none;
        border: 1px solid #afafaf;
        border-radius: 6px;
        resize: none;
        min-height: 40px;
    }

    .contact-submit-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 14px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin-top: 25px;
        margin-bottom: 20px;
        padding: 10px 25px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .contact-submit-button:hover {
        background-color: var(--textColor);
    }

    .contact-form .contact-input::placeholder,
    .contact-form .contact-textarea::placeholder {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 18px;
    }

    .social-contact-form {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 22px;
        color: #0e0d0d;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
        margin-left: -30px;
	height: 25px;
    }

    .social-contact-form li {
        list-style: none;
    }

    .social-contact-form li a {
        display: inline-block;
        margin-right: 20px;
    }

    .fb-icon {
        color: #000000;
        font-size: 30px;
    }

    .ig-icon {
        color: #000000;
        font-size: 30px;
    }

    .wp-icon {
        color: #000000;
        font-size: 30px;
    }
}

@media (1400px < width <=1580px) {
    .carousel-hover-text {
        font-family: 'STIHL-text-light', sans-serif;
        font-weight: 300;
        letter-spacing: 0.8px;
        text-align: center;
        font-size: 13px;
        margin: 10px;
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.3s;
    }

    .faq-container {
        background-color: rgb(248, 223, 182);
        width: 60%;
        border-radius: 15px;
        background-image: url('../images/stihl-animated.jpg');
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: -5px, -70px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 5px;
    }

    .contact-container-faq {
        font-family: 'STIHL-text-dem', sans-serif;
        font-size: 18px;
        position: relative;
        display: flex;
        align-self: flex-start;
        text-align: center;
        width: 75%;
        top: 8%;
        margin: 0;
        padding: 0;
    }

    .search-bar-container {
        display: flex;
        position: absolute;
        justify-content: center;
        height: 6vh;
        margin: 0;
        top: 53%;
    }

    .search_bar .search_box {
        position: relative;
        width: 50vh;
        height: 6vh;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 1rem;
    }

    .search_bar .search_box .btn {
        width: 50px;
        height: 100%;
    }

    .fa-xmark-bar {
        font-size: 1.2rem;
        margin-right: 0.3em;
        margin-bottom: 0.1rem;
    }

    .loading-products {
        font-family: 'STIHL-text-dem', sans-serif;
        font-size: 25px;
        margin-left: 30%;
    }

    .categories-brands-list {
        font-family: 'STIHL-medium', sans-serif;
        padding: 35px;
        width: 350px;
        font-size: 16px;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: 10px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 22%;
        box-sizing: border-box;
        background-color: white;
        height: 400px;
    }

    .product-image {
        max-width: 100%;
        height: 31vh;
        object-fit: contain;
    }

    .product-name {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 18px;
        margin-top: 10px;
    }

    .details-product-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 15px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        border-radius: 8px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .details-product-button:hover {
        background-color: var(--textColor);
    }

    .load-more-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 15px;
        background-color: #1f1e1e;
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin: 0 auto;
        margin-top: 45px;
        margin-bottom: 20px;
        padding: 15px 25px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .load-more-button:hover {
        background-color: var(--mainColor);
    }

    .product-image-detail {
        max-width: 40%;
        height: 45vh;
        margin: 40px 40px;
    }

    .product-info {
        flex: 1;
        text-align: center;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-name-detail {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 2em;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 35px;
    }

    .product-description-detail {
        font-family: 'STIHL-text-light', sans-serif;
        font-weight: 300;
        font-size: 20px;
        width: 600px;
        margin-bottom: 30px;
    }

    .spec-product {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 18px;
        color: #000000;
        margin-top: 20px;
    }

    .details-single-product-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 15px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        border-radius: 8px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .details-single-product-button:hover {
        background-color: var(--textColor);
    }

    .img-disclaimer {
        font-family: 'STIHL-text-light', sans-serif;
        margin-top: 25px;
        font-size: 15px;
        width: 500px;
    }

    .contact-product-wrapper {
        font-family: 'STIHL-text', sans-serif;
        font-size: 17px;
        background-color: #ffffff;
        width: 700px;
        border-radius: 10px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .contact-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 24px;
        margin-bottom: none;
    }

    .contact-subtitle {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 20px;
        margin-bottom: 20px;
        height: 10px;
    }

    .contact-form {
        font-family: 'STIHL-text', sans-serif;
        font-size: 15px;
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .contact-label {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .contact-input {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #afafaf;
        border-radius: 6px;
    }

    .contact-textarea {
        padding: 5px;
        margin-bottom: none;
        border: 1px solid #afafaf;
        border-radius: 6px;
        resize: none;
        min-height: 40px;
    }

    .contact-submit-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 14px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin-top: 25px;
        margin-bottom: 20px;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .contact-submit-button:hover {
        background-color: var(--textColor);
    }

    .contact-form .contact-input::placeholder,
    .contact-form .contact-textarea::placeholder {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 18px;
    }

    .social-contact-form {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 20px;
        color: #0e0d0d;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        margin-left: -30px;
	height: 25px;
    }

    .social-contact-form li {
        list-style: none;
    }

    .social-contact-form li a {
        display: inline-block;
        margin-right: 20px;
    }

    .fb-icon {
        color: #000000;
        font-size: 30px;
    }

    .ig-icon {
        color: #000000;
        font-size: 30px;
    }

    .wp-icon {
        color: #000000;
        font-size: 30px;
    }

    .ig-icon {
        color: #000000;
        font-size: 30px;
    }
}

@media (1230px < width <=1400px) {
    .carousel-hover-text {
        font-family: 'STIHL-text-light', sans-serif;
        font-weight: 300;
        letter-spacing: 0.8px;
        text-align: center;
        font-size: 12px;
        margin: 10px;
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.3s;
    }

    
    .faq-background {
        height: 140vh;
    }

    .faq-container {
        background-color: rgb(248, 223, 182);
        width: 75%;
        border-radius: 15px;
        background-image: url('../images/stihl-animated.jpg');
        background-size: 45%;
        background-repeat: no-repeat;
        background-position: -5px, -70px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 20px;
    }

    .search-bar-container {
        display: flex;
        position: absolute;
        justify-content: center;
        height: 6vh;
        margin: 0;
        top: 53%;
    }

    .search_bar .search_box {
        position: relative;
        width: 46vh;
        height: 6vh;
    }

    .search_bar .search_box .input-bar {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 14px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 0;
        outline: none;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 1rem;
    }

    .search_bar .search_box .btn {
        width: 50px;
        height: 100%;
    }

    .fa-xmark-bar {
        font-size: 1.2rem;
        margin-right: 1.7em;
        margin-bottom: 0.1rem;
    }

    .loading-products {
        font-family: 'STIHL-text-dem', sans-serif;
        font-size: 25px;
        margin-left: 30%;
    }

    .categories-brands-list {
        font-family: 'STIHL-medium', sans-serif;
        padding: 35px;
        width: 280px;
        font-size: 16px;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: 10px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 22%;
        box-sizing: border-box;
        background-color: white;
        height: 380px;
    }

    .product-image {
        max-width: 100%;
        height: 28vh;
        object-fit: contain;
    }

    .details-product-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 12px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        border-radius: 8px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .product-details-wrapper {
        display: flex;
        position: relative;
        align-items: center;
        background-color: #ffffff;
        width: 1000px;
        height: 65vh;
        margin-top: 70px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .product-image-detail {
        max-width: 40%;
        height: 35vh;
        margin: 30px 30px;
    }

    .product-info {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        padding: 0;
        width: 500px;
    }

    .product-name-detail {
        font-size: 25px;
    }

    .product-description-detail {
        font-weight: 300;
        font-size: 18px;
        width: 100%;
        margin: 0;
    }

    .spec-product {
        font-size: 18px;
        color: #000000;
    }

    .details-single-product-button {
        font-size: 13px;
        margin-bottom: 5px;
    }

    .img-disclaimer {
        font-size: 15px;
        width: 400px;
    }

    .product-specification-container {
        margin: 100px;
    }

    .product-description-wrapper {
        font-size: 21px;
        margin: 0 auto;
        padding: 2.7rem;
    }

    .product-specification-wrapper {
        font-size: 17px;
        padding: 2.7rem;
        width: 900px;
    }

    .product-specification {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 26px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .product-spec-tb {
        width: 100%;
        border: none;
        margin-bottom: 0;
    }

    .table tr {
        border: 1px solid #ddd;
    }

    .gray-row {
        background-color: #f8f8f8;
    }

    .spec-name {
        text-align: left;
        padding: 13px;
    }

    .spec-value {
        text-align: right;
        padding: 13px;
    }

    .related-products-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .related-products-wrapper {
        background-color: #ffffff;
        max-width: 1600px;
        margin: 0 auto;
        padding: 2.7rem;
        border-radius: 8px;
        box-shadow: 0 3px 6px #d1d1d1;
    }

    .related-products-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 26px;
    }

    .related-products-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: 10px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 22%;
        box-sizing: border-box;
        background-color: white;
        height: 400px;
    }

    .related-products-cards {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 50px;
    }

    .full-description {
        font-size: 22px;
    }

    .product-specification {
        font-size: 22px;
    }

    .contact-product-container {
        height: 140vh;
    }

    .contact-product-wrapper {
        font-family: 'STIHL-text', sans-serif;
        font-size: 17px;
        background-color: #ffffff;
	max-height: none;
	height: 110vh;
        width: 700px;
        border-radius: 10px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .contact-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 24px;
        margin-bottom: none;
    }

    .contact-subtitle {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 20px;
        margin-bottom: 20px;
        height: 10px;
    }

    .contact-form {
        font-family: 'STIHL-text', sans-serif;
        font-size: 15px;
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .contact-label {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .contact-input {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #afafaf;
        border-radius: 6px;
    }

    .contact-textarea {
        padding: 5px;
        margin-bottom: none;
        border: 1px solid #afafaf;
        border-radius: 6px;
        resize: none;
        min-height: 40px;
    }

    .contact-submit-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 14px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin-top: 25px;
        margin-bottom: 20px;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .contact-submit-button:hover {
        background-color: var(--textColor);
    }

    .contact-form .contact-input::placeholder,
    .contact-form .contact-textarea::placeholder {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 18px;
    }

    .social-contact-form {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 20px;
        color: #0e0d0d;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        margin-left: -30px;
    }

    .social-contact-form li {
        list-style: none;
    }

    .social-contact-form li a {
        display: inline-block;
        margin-right: 20px;
    }

    .fb-icon {
        color: #000000;
        font-size: 30px;
    }

    .ig-icon {
        color: #000000;
        font-size: 30px;
    }

    .wp-icon {
        color: #000000;
        font-size: 30px;
    }

    .ig-icon {
        color: #000000;
        font-size: 30px;
    }
}

@media (992px <=width <=1230px) {
    .container-text {
        z-index: 1;
        margin: auto;
    }

    .container-text h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 3.7em;
    }

    .container-text p {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 1.7em;
        color: #EEE;
    }

    .grid-container {
        background-color: var(--mainColor);
        padding: 3em;
    }

    .wrapper-imgs {
        display: grid;
        justify-content: center;
        gap: 1px;
    }

    .grid-item {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .grid-item img {
        width: 75%;
        height: auto;
        margin: 6px;
        border-radius: 15px;
        background-color: var(--mainColor);
        opacity: 0.6;
    }

    .image-first-column {
        position: relative;
        left: 11%;
    }

    .image-second-column {
        position: relative;
        right: 10%;
    }

    .carousel-container {
        background-color: var(--mainColor);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        margin-bottom: 0;
    }

    .tips-stihl {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 30px;
        text-decoration: underline;
        margin-bottom: 50px;
    }

    .carousel-wrapper {
        width: 100%;
        overflow: hidden;
        margin-bottom: 80px;
    }

    .slick-slider {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .slick-list {
        width: 80%;
    }

    .slider-img {
        position: relative;
        border-radius: 2vh;
        width: 80%;
    }

    .custom-prev-arrow,
    .custom-next-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        z-index: 1;
        font-size: 15px;
        color: #fff;
        overflow-y: hidden;
    }

    .custom-prev-arrow {
        left: 9vh;
    }

    .custom-next-arrow {
        right: 9vh;
    }

    .custom-prev-arrow:hover,
    .custom-next-arrow:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .slide {
        display: flex !important;
        justify-content: center !important;
        position: relative;
    }

    .overlay {
        border-radius: 2vh;
        position: absolute;
        bottom: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        width: 80%;
        height: 0;
        background-color: rgba(0, 0, 0, 0.85);
        color: #fff;
        opacity: 0;
        transition: height 0.6s, opacity 0.6s;
        margin-left: 10%;
    }

    .slide:hover .overlay {
        height: 100%;
        opacity: 1;
    }

    .carousel-hover-text {
        font-family: 'STIHL-text-light', sans-serif;
        font-weight: 300;
        letter-spacing: 0.8px;
        text-align: center;
        font-size: 14px;
        margin: 6px;
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.3s;
    }

    .slide:hover .carousel-hover-text {
        opacity: 1;
    }

    .stihl-link {
        color: var(--mainColor);
    }

    .faq-container {
        background-color: rgb(248, 223, 182);
        width: 60%;
        border-radius: 15px;
        background-image: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px;
        overflow: hidden;
    }

    .question {
        width: 100%;
        padding: 12px;
    }

    .contact-container-faq {
        font-size: 18px;
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 10px;
        top: 0;
    }

    .contact-icons-faq {
        display: flex;
        align-self: center;
    }

    .icons-container-faq {
        margin: auto;
    }

    .search-bar-container {
        display: flex;
        position: absolute;
        justify-content: center;
        height: 6vh;
        margin: 0;
        top: 53%;
    }

    .search_bar .search_box {
        position: relative;
        width: 44vh;
        height: 6vh;
    }

    .search_bar .search_box .input-bar {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 15px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 0;
        outline: none;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 1rem;
    }

    .search_bar .search_box .btn {
        width: 50px;
        height: 100%;
    }

    .fa-xmark-bar {
        font-size: 1.2rem;
        margin-right: 1em;
        margin-bottom: 0.1rem;
    }

    .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
    }

    .filter-title:hover {
        color: var(--mainColor);
    }

    .filter-title-icon {
        font-size: 16px;
        margin-left: 5px;
    }

    .wrap-products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .products-grid {
        width: 100%;
    }

    .categories-brands-list {
        position: absolute;
        top: 69.5%;
        left: 14.5%;
        width: 26%;
        margin-left: 10%;
        background-color: #f4f4f4;
        z-index: 1;
        display: none;
        box-shadow: 0 1px 7px rgba(51, 51, 51, .33);
    }

    .brands-column {
        margin-left: 36%;
    }

    .categories-brands-list h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 18px;
    }

    .categories-brands-list ul {
        list-style-type: none;
        padding: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }

    .categories-brands-list.expanded ul {
        max-height: 200px;
    }

    .categories-brands-list li {
        margin-bottom: 10px;
    }

    .categories-brands-list a {
        text-decoration: none;
        color: #333;
        display: block;
        padding: 5px;
        transition: background-color 0.2s ease-in-out;
    }

    .categories-brands-list a:hover {
        background-color: #ddd;
        border-radius: 5px;
        color: #222;
    }

    .categories-brands-list a.active {
        font-weight: bold;
        color: var(--mainColor);
        background-color: #ddd;
        border-radius: 5px;
    }

    .filters-products-container {
        min-height: 210vh;
    }

    .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
    }

    .brands-title {
        margin-left: 1px;
    }

    .filter-title:hover {
        color: var(--mainColor);
    }

    .filter-title-icon {
        display: none;
    }

    .filter-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .filter-button {
        font-family: 'STIHL-text', sans-serif;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 350px;
        margin-top: 30px;
        border-radius: 8px;
        min-width: 70px;
        height: 50px;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        border: 1px solid rgb(136, 136, 136);
    }

    .arrow-button {
        font-size: 15px;
        margin-left: 28%;
    }

    .overlay-list {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
        display: block;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 12px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 23%;
        box-sizing: border-box;
        background-color: white;
        height: 450px;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    .product-image {
        max-width: 100%;
        height: 34vh;
        object-fit: contain;
    }

    .product-image {
        max-width: 100%;
        height: 31.50vh;
    }

    .product-name {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 18px;
        margin-top: 10px;
    }

    .details-product-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 15px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        border-radius: 8px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .loading-products {
        display: none;
    }

    .details-product-button:hover {
        background-color: var(--textColor);
    }

    .product-details-wrapper {
        display: flex;
        position: relative;
        align-items: center;
        background-color: #ffffff;
        width: 900px;
        height: 60vh;
        margin-top: 70px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .product-image-detail {
        max-width: 40%;
        height: 30vh;
        margin: 30px 30px;
    }

    .product-info {
        flex: 1;
        text-align: center;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-name-detail {
        font-size: 19px;
    }

    .product-description-detail {
        font-weight: 300;
        font-size: 15px;
        width: 480px;
        margin: 0;
    }

    .spec-product {
        font-size: 13px;
        color: #000000;
    }

    .details-single-product-button {
        font-size: 11px;
        margin-bottom: 2px;
        margin-top: 15px;
    }

    .img-disclaimer {
        font-size: 13px;
        width: 400px;
    }

    .product-specification-container {
        display: flex;
        flex-direction: column;
    }

    .product-description-wrapper {
        font-size: 18px;
        width: auto;
        margin-bottom: 10%;
    }

    .product-specification-wrapper {
        font-size: 17px;
        width: auto;
    }

    .related-products-wrapper {
        width: 1900px;
    }

    .related-products-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 26px;
    }

    .related-products-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: 5%;
        padding: 25px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 40%;
        box-sizing: border-box;
        background-color: white;
        height: 450px;
    }

    .related-products-cards {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 50px;
    }

    .contact-product-wrapper {
        font-family: 'STIHL-text', sans-serif;
        font-size: 17px;
        background-color: #ffffff;
        width: 700px;
        border-radius: 10px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .contact-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 24px;
    }

    .contact-subtitle {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 20px;
        margin-bottom: 20px;
        height: 10px;
    }

    .contact-form {
        font-family: 'STIHL-text', sans-serif;
        font-size: 15px;
        margin: auto;
        display: flex;
        flex-direction: column;
	height: 75vh;
    }

    .contact-label {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .contact-input {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #afafaf;
        border-radius: 6px;
    }

    .contact-textarea {
        padding: 5px;
        margin-bottom: none;
        border: 1px solid #afafaf;
        border-radius: 6px;
        resize: none;
        min-height: 40px;
    }

    .contact-submit-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 14px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin-top: 25px;
        margin-bottom: 20px;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .contact-submit-button:hover {
        background-color: var(--textColor);
    }

    .contact-form .contact-input::placeholder,
    .contact-form .contact-textarea::placeholder {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 18px;
    }

    .social-contact-form {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 20px;
        color: #0e0d0d;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        margin-left: -30px;
	height: 25px;
    }

    .social-contact-form li {
        list-style: none;
    }

    .social-contact-form li a {
        display: inline-block;
        margin-right: 20px;
    }

    .fb-icon {
        color: #000000;
        font-size: 30px;
    }

    .ig-icon {
        color: #000000;
        font-size: 30px;
    }

    .wp-icon {
        color: #000000;
        font-size: 30px;
    }
}

@media (768px <=width <=992px) {

    .container-text {
        z-index: 1;
        margin: auto;
    }

    .container-text h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 3em;
    }

    .container-text p {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 1.7em;
        color: #EEE;
    }

    .logo img {
        width: 18vh;
        margin-left: 20px;
    }

    .grid-container {
        background-color: var(--mainColor);
        padding: 3em;
    }

    .wrapper-imgs {
        display: grid;
        justify-content: center;
        gap: 1px;
    }

    .grid-item {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .grid-item img {
        width: 75%;
        height: auto;
        margin: 6px;
        border-radius: 15px;
        background-color: var(--mainColor);
        opacity: 0.6;
    }

    .image-first-column {
        position: relative;
        left: 11%;
    }

    .image-second-column {
        position: relative;
        right: 10%;
    }

    .carousel-container {
        background-color: var(--mainColor);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        margin-bottom: 0;
    }

    .tips-stihl {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 30px;
        text-decoration: underline;
        margin-bottom: 30px;
    }

    .carousel-wrapper {
        width: 70%;
        overflow: hidden;
        margin-bottom: 80px;
    }

    .slick-slider {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .slick-list {
        width: 80%;
    }

    .slider-img {
        position: relative;
        border-radius: 2vh;
        width: 80%;
    }

    .custom-prev-arrow,
    .custom-next-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        z-index: 1;
        font-size: 13px;
        color: #fff;
        overflow-y: hidden;
    }

    .custom-prev-arrow {
        left: 7vh;
    }

    .custom-next-arrow {
        right: 7vh;
    }

    .custom-prev-arrow:hover,
    .custom-next-arrow:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .slide {
        display: flex !important;
        justify-content: center !important;
        position: relative;
    }

    .overlay {
        border-radius: 2vh;
        position: absolute;
        bottom: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        width: 80%;
        height: 0;
        background-color: rgba(0, 0, 0, 0.85);
        color: #fff;
        opacity: 0;
        transition: height 0.6s, opacity 0.6s;
        margin-left: 10%;
    }

    .slide:hover .overlay {
        height: 100%;
        opacity: 1;
    }

    .carousel-hover-text {
        font-family: 'STIHL-text-light', sans-serif;
        font-weight: 300;
        letter-spacing: 0.8px;
        text-align: center;
        font-size: 14px;
        padding: 6px;
        margin: 6px;
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.3s;
    }

    .slide:hover .carousel-hover-text {
        opacity: 1;
    }

    .stihl-link {
        color: var(--mainColor);
    }

    .footer {
        background-color: #1f1e1e;
        padding: 20px;
    }

    .logo-footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo-footer img {
        width: 25vh;
    }

    .links-footer {
        display: flex;
        list-style: none;
        justify-content: center;
        margin-top: 30px;
        gap: 40px;
    }

    .links-footer li a {
        font-family: 'STIHL-text-bold', sans-serif;
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        text-decoration: none;
        transition: color 0.5s, font-size 0.5s;
    }

    .links-footer li a:hover {
        color: var(--mainColor);
        font-size: 19px;
    }

    .line-through {
        width: 100%;
        border-bottom: 1px solid #979090;
        margin-top: 45px;
    }

    .contact-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        margin: 0;
        margin-left: 0;
        margin-top: 40px;
        color: #ffffff;
    }

    .icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .faq-container {
        background-color: rgb(248, 223, 182);
        width: 60%;
        border-radius: 15px;
        background-image: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px;
        overflow: hidden;
    }

    .question {
        width: 100%;
        padding: 7px;
    }

    .contact-container-faq {
        font-size: 16px;
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 10px;
        top: 0;
    }

    .icons-container-faq {
        width: 100%;
    }

    .contact-icons-faq {
        display: flex;
        align-self: center;
    }

    .contact-icons-faq a,
    .contact-icons-faq span {
        margin: 0 5px;
        display: inline-block;
    }

    .icon-contact {
        font-size: 30px;
    }

    .text-icons {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 17px;
        margin: 0 20px;
    }

    .social-network {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 40px;
        text-decoration: none;
        margin-top: 50px;
    }

    .social-icons {
        font-size: 30px;
        color: #ffffff;
    }

    .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
    }

    .filter-title:hover {
        color: var(--mainColor);
    }

    .filter-title-icon {
        font-size: 16px;
        margin-left: 5px;
    }

    .animated-header {
        height: 25vh;
    }

    .search-bar-container {
        display: flex;
        position: absolute;
        justify-content: center;
        height: 5vh;
        margin: 0;
        top: 19%;
    }

    .search_bar .search_box {
        position: relative;
        width: 42vh;
        height: 5vh;
    }

    .search_bar .search_box .input-bar {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 14px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 0;
        outline: none;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 0.8rem;
    }

    .search_bar .search_box .btn {
        width: 40px;
        height: 100%;
    }

    .fa-xmark-bar {
        font-size: 1rem;
        margin-right: 0.2em;
        margin-bottom: 0.2rem;
    }

    .animated-imgs {
        display: none;
    }

    .ms-animated {
        display: none;
    }

    .wrap-products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .categories-brands-list {
        position: absolute;
        top: 34.5%;
        left: 14.5%;
        width: 26%;
        margin-left: 10%;
        background-color: #f4f4f4;
        z-index: 1;
        display: none;
        box-shadow: 0 1px 7px rgba(51, 51, 51, .33);
    }

    .brands-column {
        margin-left: 36%;
    }

    .categories-brands-list h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 15px;
    }

    .categories-brands-list ul {
        list-style-type: none;
        padding: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }

    .categories-brands-list.expanded ul {
        max-height: 200px;
    }

    .categories-brands-list li {
        margin-bottom: 10px;
    }

    .categories-brands-list a {
        font-size: 12px;
        text-decoration: none;
        color: #333;
        display: block;
        padding: 0;
        transition: background-color 0.2s ease-in-out;
    }

    .filters-products-container {
        min-height: 210vh;
    }

    .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
    }

    .brands-title {
        margin-left: 1px;
    }

    .filter-title:hover {
        color: var(--mainColor);
    }

    .filter-title-icon {
        display: none;
    }

    .filter-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .filter-button {
        font-family: 'STIHL-text', sans-serif;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 350px;
        margin-top: 30px;
        border-radius: 8px;
        min-width: 70px;
        height: 50px;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        border: 1px solid rgb(136, 136, 136);
    }

    .arrow-button {
        font-size: 15px;
        margin-left: 28%;
    }

    .overlay-list {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
        display: block;
    }

    .filters-products-container {
        display: flex;
        justify-content: center;
    }

    .products-grid {
        width: 100%;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 12px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 38%;
        box-sizing: border-box;
        background-color: white;
        height: 400px;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    .product-image {
        max-width: 100%;
        height: 34vh;
        object-fit: contain;
    }

    .product-name {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 15px;
        margin-top: 10px;
    }

    .details-product-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 13px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 12px;
        text-align: center;
        text-decoration: none;
        border-radius: 8px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .loading-products {
        display: none;
    }

    .product-details-wrapper {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        width: 700px;
        height: 55vh;
        margin-top: 70px;
        padding: 0;
        border-radius: 8px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .product-image-detail {
        max-width: 40%;
        height: 30vh;
        margin: 30px 5px;
    }

    .product-info {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        padding: 0;
    }

    .product-name-detail {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .product-description-detail {
        font-weight: 300;
        font-size: 15px;
        width: 350px;
        margin: 0;
        margin-top: 10px;
    }

    .spec-product {
        font-size: 14px;
        color: #000000;
    }

    .details-single-product-button {
        font-size: 10px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .img-disclaimer {
        font-size: 13px;
        width: 400px;
        margin: 0;
    }

    .product-specification-container {
        display: flex;
        flex-direction: column;
    }

    .full-description {
        font-size: 21px;
    }

    .product-specification {
        font-size: 21px;
    }

    .product-description-wrapper {
        font-size: 17px;
        width: auto;
        margin-bottom: 10%;
    }

    .product-specification-wrapper {
        font-size: 15px;
        width: auto;
    }

    .related-products-wrapper {
        width: auto;
    }

    .related-products-title {
        font-size: 21px;
    }

    .related-products-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: 5%;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 40%;
        box-sizing: border-box;
        background-color: white;
        height: 400px;
    }

    .related-products-cards {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 50px;
    }

    .contact-product-wrapper {
        font-family: 'STIHL-text', sans-serif;
        font-size: 17px;
        background-color: #ffffff;
        width: 600px;
        border-radius: 10px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .contact-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 24px;
        margin-bottom: none;
    }

    .contact-subtitle {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 20px;
        margin-bottom: 20px;
        height: 10px;
    }

    .contact-form {
        font-family: 'STIHL-text', sans-serif;
        font-size: 15px;
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .contact-label {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .contact-input {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #afafaf;
        border-radius: 6px;
    }

    .contact-textarea {
        padding: 5px;
        margin-bottom: none;
        border: 1px solid #afafaf;
        border-radius: 6px;
        resize: none;
        height: 150px;
    }

    .contact-submit-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 14px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin-top: 25px;
        margin-bottom: 20px;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .contact-form .contact-input::placeholder,
    .contact-form .contact-textarea::placeholder {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 16px;
    }

    .social-contact-form {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 18px;
        color: #0e0d0d;
        display: flex;
        align-items: center;
        justify-content: flex-start;
	height: 25px;
    }

    .social-contact-form li {
        list-style: none;
    }

    .social-contact-form li a {
        display: inline-block;
        margin-right: 12px;
    }

    .fb-icon {
        color: #000000;
        font-size: 30px;
    }

    .ig-icon {
        color: #000000;
        font-size: 30px;
    }

    .wp-icon {
        color: #000000;
        font-size: 30px;
    }
}

@media (576px <=width <=768px) {
    .container-text {
        z-index: 1;
        margin: auto;
        padding: 5px;
    }

    .container-text h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 2.4em;
        padding: 3px;
    }

    .container-text p {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 1.4em;
        color: #EEE;
    }

    .logo img {
        width: 16vh;
        margin-left: 20px;
    }

    .featured-h2 {
        font-family: 'STIHL-text-dem', sans-serif;
        font-weight: 600;
        padding: 40px;
        text-transform: uppercase;
        color: var(--mainColor);
        text-decoration: underline;
        letter-spacing: 3px;
        display: flex;
        margin-bottom: 30px;
        justify-content: center;
    }

    .grid-wrapper {
        background-color: white;
        width: 100%;
    }

    .grid-layout {
        grid-template-columns: auto;
    }


    .grid-container {
        background-color: var(--mainColor);
        padding: 3em;
    }

    .wrapper-imgs {
        display: grid;
        justify-content: center;
        gap: 15px;
    }

    .grid-item {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .grid-item img {
        width: 100%;
        height: auto;
        margin: 6px;
        border-radius: 15px;
        background-color: var(--mainColor);
        opacity: 0.6;
    }

    .image-first-column {
        position: relative;
        left: 1%;
    }

    .image-second-column {
        position: relative;
        right: 1%;
    }

    .custom-prev-arrow,
    .custom-next-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        z-index: 1;
        font-size: 13px;
        color: #fff;
        overflow-y: hidden;
    }

    .custom-prev-arrow {
        left: 5vh;
    }

    .custom-next-arrow {
        right: 5vh;
    }

    .carousel-hover-text {
        letter-spacing: 0.8px;
        text-align: center;
        font-size: 14px;
        padding: 6px;
        margin: 6px;
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.3s;
    }

    .icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-contact {
        font-size: 30px;
    }

    .text-icons {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 17px;
        margin: 0 20px;
    }

    .text-number {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 17px;
        margin: 0 20px;
    }

    .social-network {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 40px;
        text-decoration: none;
        margin-top: 50px;
    }

    .social-icons {
        font-size: 30px;
        color: #ffffff;
    }

    .faq-container {
        background-color: rgb(248, 223, 182);
        width: 60%;
        border-radius: 15px;
        background-image: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        overflow: hidden;
    }

    .question {
        width: 100%;
        padding: 5px;
    }

    .question-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .question-header h4 {
        font-family: 'STIHL-text', sans-serif;
        font-size: 14px;
        cursor: pointer;
        margin-right: 10px;
        overflow: hidden;
    }

    .answer-text {
        font-size: 14px;
    }

    .contact-container-faq {
        font-size: 14px;
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 10px;
        top: 0;
    }

    .contact-icons-faq {
        display: flex;
        align-self: center;
    }

    .contact-icons-faq a,
    .contact-icons-faq span {
        margin: 0 5px;
        display: inline-block;
    }

    .icon-container {
        display: flex;
        align-items: center;
    }

    .icons-container-faq {
        width: 100%;
    }

    .icons-faq {
        font-size: 18px;
        color: var(--mainColor);
        display: inline-block;
    }

    .wp-faq {
        font-size: 28px;
        margin-top: 3px;
    }

    .mbl {
        font-size: 18px;
        color: var(--mainColor);
        display: inline-block;
        margin-left: 5px;
    }

    .text-number-faq {
        font-size: 14px;
    }

    .wrap-products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .animated-header {
        height: 25vh;
    }

    .search-bar-container {
        display: flex;
        position: absolute;
        justify-content: center;
        height: 5vh;
        margin: 0;
        top: 19%;
    }

    .search_bar .search_box {
        position: relative;
        width: 40vh;
        height: 5vh;
    }

    .search_bar .search_box .input-bar {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 14px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 0;
        outline: none;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 0.8rem;
    }

    .search_bar .search_box .btn {
        width: 40px;
        height: 100%;
    }

    .fa-xmark-bar {
        font-size: 1rem;
        margin-right: 0.8em;
        margin-bottom: 0.2rem;
    }

    .animated-imgs {
        display: none;
    }

    .ms-animated {
        display: none;
    }

    .categories-brands-list {
        position: absolute;
        top: 34.4%;
        left: 5.4%;
        width: 35%;
        margin-left: 10%;
        background-color: #f4f4f4;
        z-index: 1;
        display: none;
        box-shadow: 0 1px 7px rgba(51, 51, 51, .33);
    }

    .brands-column {
        margin-left: 45%;
    }

    .categories-brands-list h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 13px;
    }

    .categories-brands-list ul {
        list-style-type: none;
        padding: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }

    .categories-brands-list.expanded ul {
        max-height: 200px;
    }

    .categories-brands-list li {
        margin-bottom: 10px;
    }

    .categories-brands-list a {
        font-size: 11px;
        text-decoration: none;
        color: #333;
        display: block;
        padding: 5px;
        transition: background-color 0.2s ease-in-out;
    }

    .categories-brands-list a:hover {
        background-color: #ddd;
        border-radius: 5px;
        color: #222;
    }

    .categories-brands-list a.active {
        font-weight: bold;
        color: var(--mainColor);
        background-color: #ddd;
        border-radius: 5px;
    }

    .filters-products-container {
        min-height: 210vh;
    }

    .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
    }

    .brands-title {
        margin-left: 1px;
    }

    .filter-title:hover {
        color: var(--mainColor);
    }

    .filter-title-icon {
        display: none;
    }

    .filter-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .filter-button {
        font-family: 'STIHL-text', sans-serif;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 350px;
        margin-top: 30px;
        border-radius: 8px;
        min-width: 70px;
        height: 50px;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        border: 1px solid rgb(136, 136, 136);
    }

    .arrow-button {
        font-size: 15px;
        margin-left: 28%;
    }

    .overlay-list {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
        display: block;
    }

    .loading-products {
        font-family: 'STIHL-text-dem', sans-serif;
        font-size: 17px;
        display: none;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 12px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 23%;
        box-sizing: border-box;
        background-color: white;
        height: 450px;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    .product-image {
        max-width: 100%;
        height: 34vh;
        object-fit: contain;
    }

    .footer {
        background-color: #1f1e1e;
        padding: 20px;
    }

    .logo-footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo-footer img {
        width: 20vh;
    }

    .links-footer {
        display: flex;
        list-style: none;
        justify-content: center;
        margin-top: 30px;

    }

    .links-footer li a {
        font-family: 'STIHL-text-bold', sans-serif;
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        text-decoration: none;
        transition: color 0.5s, font-size 0.5s;
    }

    .links-footer li a:hover {
        color: var(--mainColor);
        font-size: 19px;
    }

    .contact-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 20px;
        margin-top: 40px;
        color: #ffffff;
    }

    .icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-contact {
        font-size: 30px;
    }

    .text-icons {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 17px;
        margin: 0 20px;
    }

    .text-number {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 17px;
        margin: 0 20px;
    }

    .social-network {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 40px;
        text-decoration: none;
        margin-top: 50px;
    }

    .social-icons {
        font-size: 30px;
        color: #ffffff;
    }

    .products-grid {
        width: 100% !important;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 12px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 45%;
        box-sizing: border-box;
        background-color: white;
        height: 450px;
    }

    .product-details-wrapper {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        width: 510px;
        height: 70vh;
        margin-top: 90px;
        padding: 0;
        border-radius: 8px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .product-image-detail {
        max-width: 50%;
        height: 23vh;
        margin: 25px;
    }

    .product-info {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        padding: 10px;
        width: 100%;
    }

    .product-name-detail {
        font-size: 18px;
        margin-bottom: 25px;
    }

    .product-description-detail {
        font-size: 14px;
        width: 90%;
        margin: 5px;
    }

    .spec-product {
        font-size: 14px;
        color: #000000;
        margin: 5px;
    }

    .details-single-product-button {
        border-radius: 4px;
        font-size: 9px;
        padding: 12px;
        margin: 10px;
    }

    .img-disclaimer {
        font-size: 12px;
        margin: 5px;
        width: 400px;
    }

    .product-specification-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .full-description {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 21px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .product-specification {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 21px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .product-description-wrapper {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        background-color: #ffffff;
        width: 500px;
        margin: 0;
        margin-bottom: 30%;
        padding: 1.7rem;
        border-radius: 8px;
        box-shadow: 0 3px 6px #d1d1d1;
    }

    .product-specification-wrapper {
        font-size: 13px;
        width: 450px;
        margin: 0;
        padding: 18px;
        text-align: left;
    }

    .related-products-wrapper {
        min-width: 500px;
        padding: 1rem;
    }

    .related-products-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 21px;
    }

    .related-products-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: 10px;
        padding: 25px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 45%;
        box-sizing: border-box;
        background-color: white;
        height: 350px;
    }

    .related-products-cards {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 50px;
    }

    .contact-product-wrapper {
        font-family: 'STIHL-text', sans-serif;
        font-size: 14px;
        background-color: #ffffff;
        width: 500px;
        margin: 0;
        border-radius: 10px;
        padding: 0;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .contact-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 24px;
        margin: 0;
        padding: 15px;
    }

    .contact-subtitle {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 16px;
        padding: 15px;
        margin: 0;
    }

    .contact-form {
        font-family: 'STIHL-text', sans-serif;
        font-size: 12px;
        margin: 0;
        height: 600px;
        padding: 15px;
        display: flex;
        flex-direction: column;
	height: 75vh;
    }

    .contact-label {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .contact-input {
        padding: 8px;
        margin-bottom: 10px;
        border: 1px solid #afafaf;
        border-radius: 6px;
    }

    .contact-textarea {
        padding: 5px;
        margin-bottom: none;
        border: 1px solid #afafaf;
        border-radius: 6px;
        resize: none;
        min-height: 40px;
    }

    .contact-submit-button {
        font-size: 12px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin: 0;
        margin-top: 10px;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .social-contact-form {
        font-size: 13px;
        justify-content: flex-start;
        padding: 18px;
        color: #0e0d0d;
        display: flex;
        align-items: center;
        margin: 0;
        margin-top: 20px;
        margin-left: 0;
	height: 25px;
    }

    .social-contact-form li {
        list-style: none;
    }

    .social-contact-form li a {
        display: inline-block;
    }

    .fb-icon {
        color: #000000;
        font-size: 25px;
    }

    .ig-icon {
        color: #000000;
        font-size: 25px;
    }

    .wp-icon {
        color: #000000;
        font-size: 25px;
    }
}

@media (480px <=width <=576px) {
    .container-text {
        z-index: 1;
        margin: auto;
        padding: 5px;
    }

    .container-text h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 2.4em;
        padding: 3px;
    }

    .container-text p {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 1.4em;
        color: #EEE;
    }

    .logo img {
        width: 16vh;
        margin-left: 20px;
    }

    .featured-h2 {
        font-family: 'STIHL-text-dem', sans-serif;
        font-weight: 600;
        padding: 40px;
        text-transform: uppercase;
        color: var(--mainColor);
        text-decoration: underline;
        letter-spacing: 3px;
        display: flex;
        margin-bottom: 30px;
        justify-content: center;
    }

    .grid-wrapper {
        background-color: white;
        width: 100%;
    }

    .grid-layout {
        grid-template-columns: auto;
    }


    .grid-container {
        background-color: var(--mainColor);
        padding: 3em;
    }

    .wrapper-imgs {
        display: grid;
        justify-content: center;
        gap: 15px;
    }

    .grid-item {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .grid-item img {
        width: 100%;
        height: auto;
        margin: 6px;
        border-radius: 15px;
        background-color: var(--mainColor);
        opacity: 0.6;
    }

    .image-first-column {
        position: relative;
        left: 1%;
    }

    .image-second-column {
        position: relative;
        right: 1%;
    }

    .custom-prev-arrow,
    .custom-next-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        z-index: 1;
        font-size: 10px;
        color: #fff;
        overflow-y: hidden;
    }

    .custom-prev-arrow {
        left: 5vh;
    }

    .custom-next-arrow {
        right: 5vh;
    }

    .carousel-hover-text {
        letter-spacing: 0.8px;
        text-align: center;
        font-size: 13px;
        padding: 6px;
        margin: 6px;
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.3s;
    }

    .footer {
        background-color: #1f1e1e;
        padding: 20px;
    }

    .logo-footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo-footer img {
        width: 20vh;
    }

    .links-footer {
        display: flex;
        list-style: none;
        justify-content: center;
        margin-top: 30px;

    }

    .links-footer li a {
        font-size: 16px;
        color: #ffffff;
        text-decoration: none;
        transition: color 0.5s, font-size 0.5s;
    }

    .contact-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 10px;
        padding: 10px;
        margin-top: 40px;
        color: #ffffff;
    }

    .icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
    }

    .icon-contact {
        font-size: 20px;
    }

    .text-icons {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 16px;
        margin: 0 10px;
    }

    .text-number {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 16px;
        margin: 0 10px;
    }

    .social-network {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 40px;
        text-decoration: none;
        margin-top: 50px;
    }

    .social-icons {
        font-size: 30px;
        color: #ffffff;
    }

    .icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-contact {
        font-size: 30px;
    }

    .text-icons {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 17px;
        margin: 0 20px;
    }

    .text-number {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 17px;
        margin: 0 20px;
    }

    .social-network {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 40px;
        text-decoration: none;
        margin-top: 50px;
    }

    .social-icons {
        font-size: 30px;
        color: #ffffff;
    }

    .faq-container {
        background-color: rgb(248, 223, 182);
        width: 60%;
        border-radius: 15px;
        background-image: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        overflow: hidden;
    }

    .question {
        width: 100%;
        padding: 5px;
    }

    .question-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .question-header h4 {
        font-family: 'STIHL-text', sans-serif;
        font-size: 14px;
        cursor: pointer;
        margin-right: 10px;
        overflow: hidden;
    }

    .answer-text {
        font-size: 14px;
    }

    .contact-container-faq {
        font-size: 14px;
        position: relative;
        display: flex;
        text-align: center;
        align-self: center;
        width: 100%;
        margin: 0;
        padding: 10px;
        top: 0;
    }

    .contact-icons-faq {
        display: flex;
        align-self: center;
    }

    .contact-icons-faq a,
    .contact-icons-faq span {
        margin: 0 5px;
        display: inline-block;
    }

    .icon-container {
        display: flex;
        align-items: center;
    }

    .icons-container-faq {
        width: 100%;
    }

    .icons-faq {
        font-size: 18px;
        color: var(--mainColor);
        display: inline-block;
    }

    .wp-faq {
        font-size: 28px;
        margin-top: 3px;
    }

    .mbl {
        font-size: 18px;
        color: var(--mainColor);
        display: inline-block;
        margin-left: 5px;
    }

    .text-number-faq {
        font-size: 14px;
    }

    .wrap-products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .animated-header {
        height: 25vh;
    }

    .search-bar-container {
        display: flex;
        position: absolute;
        justify-content: center;
        height: 5vh;
        margin: 0;
        top: 19%;
    }

    .search_bar .search_box {
        position: relative;
        width: 35vh;
        height: 5vh;
    }

    .search_bar .search_box .input-bar {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 13px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 0;
        outline: none;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 0.8rem;
    }

    .search_bar .search_box .btn {
        width: 40px;
        height: 100%;
    }

    .fa-xmark-bar {
        font-size: 1rem;
        margin-right: 1.2em;
        margin-bottom: 0.2rem;
    }

    .animated-imgs {
        display: none;
    }

    .ms-animated {
        display: none;
    }

    .categories-brands-list {
        position: absolute;
        top: 34.4%;
        left: 4%;
        width: 37%;
        margin-left: 10%;
        background-color: #f4f4f4;
        z-index: 1;
        display: none;
        box-shadow: 0 1px 7px rgba(51, 51, 51, .33);
    }

    .brands-column {
        margin-left: 45%;
    }

    .categories-brands-list h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 13px;
    }

    .categories-brands-list ul {
        list-style-type: none;
        padding: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }

    .categories-brands-list.expanded ul {
        max-height: 200px;
    }

    .categories-brands-list li {
        margin-bottom: 10px;
    }

    .categories-brands-list a {
        font-size: 10px;
        text-decoration: none;
        color: #333;
        display: block;
        padding: 1px;
        transition: background-color 0.2s ease-in-out;
    }

    .categories-brands-list a:hover {
        background-color: #ddd;
        border-radius: 5px;
        color: #222;
    }

    .categories-brands-list a.active {
        font-weight: bold;
        color: var(--mainColor);
        background-color: #ddd;
        border-radius: 5px;
    }

    .filters-products-container {
        min-height: 210vh;
    }

    .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
    }

    .brands-title {
        margin-left: 1px;
    }

    .filter-title:hover {
        color: var(--mainColor);
    }

    .filter-title-icon {
        display: none;
    }

    .filter-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .filter-button {
        font-family: 'STIHL-text', sans-serif;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        margin-top: 30px;
        border-radius: 8px;
        min-width: 70px;
        height: 50px;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        border: 1px solid rgb(136, 136, 136);
    }

    .arrow-button {
        font-size: 15px;
        margin-left: 28%;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    .overlay-list {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
        display: block;
    }

    .products-grid {
        width: 100% !important;
    }

    .loading-products {
        font-family: 'STIHL-text-dem', sans-serif;
        font-size: 17px;
        display: none;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 12px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 46%;
        box-sizing: border-box;
        background-color: white;
        height: 400px;
    }

    .details-product-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 13px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 13px;
        text-align: center;
        text-decoration: none;
        border-radius: 5px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .product-image {
        max-width: 100%;
        height: 34vh;
        object-fit: contain;
    }

    .product-details-wrapper {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        width: 400px;
        height: 65vh;
        margin-top: 90px;
        padding: 0;
        border-radius: 8px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .product-image-detail {
        max-width: 40%;
        height: 20vh;
        margin: 25px;
    }

    .product-info {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        padding: 10px;
        width: 100%;
    }

    .product-name-detail {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .product-description-detail {
        font-size: 13px;
        width: 90%;
        margin: 5px;
    }

    .spec-product {
        font-size: 12px;
        color: #000000;
        margin: 5px;
    }

    .details-single-product-button {
        border-radius: 4px;
        font-size: 8px;
        padding: 12px;
        margin: 10px;
    }

    .img-disclaimer {
        font-size: 12px;
        margin: 5px;
        width: 300px;
    }

    .product-specification-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .full-description {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 21px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .product-specification {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 21px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .product-description-wrapper {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        background-color: #ffffff;
        width: 400px;
        margin: 0;
        margin-bottom: 30%;
        padding: 1.7rem;
        border-radius: 8px;
        box-shadow: 0 3px 6px #d1d1d1;
    }

    .product-specification-wrapper {
        font-size: 13px;
        width: 400px;
        margin: 0;
        padding: 18px;
        text-align: left;
    }

    .related-products-wrapper {
        min-width: 400px;
        padding: 1rem;
    }

    .related-products-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 20px;
        padding: 10px;
    }

    .responsive-card {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        margin: 15px;
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
        height: 250px;
    }

    .related-products-cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .responsive-card-img {
        max-width: 35%;
        height: 30vh;
        object-fit: contain;
    }

    .responsive-card-name {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 12px;
        margin: 0;
        height: 25px;
    }

    .responsive-card-btn {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 12px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 10px;
        margin: 5px;
        width: 70%;
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .contact-product-wrapper {
        font-family: 'STIHL-text', sans-serif;
        font-size: 14px;
        background-color: #ffffff;
        width: 420px;
        border-radius: 10px;
        padding: 0;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .contact-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 19px;
        margin: 10px;
        margin-left: 6%;
        padding: 0;
    }

    .contact-subtitle {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 13px;
        padding: 0;
        margin: 10px;
        margin-left: 6%;
    }

    .contact-form {
        font-family: 'STIHL-text', sans-serif;
        font-size: 10px;
        margin: 25px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .contact-label {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .contact-input {
        font-size: 12px;
        padding: 5px;
        margin-bottom: 10px;
        border: 1px solid #afafaf;
        border-radius: 2px;
    }

    .contact-textarea {
        padding: 5px;
        margin-bottom: none;
        border: 1px solid #afafaf;
        border-radius: 6px;
        resize: none;
        height: 150px;
    }

    .contact-submit-button {
        font-size: 12px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin: 0;
        margin-top: 10px;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .social-contact-form {
        font-size: 13px;
        justify-content: flex-start;
        color: #0e0d0d;
        display: flex;
    }

    .social-contact-form li {
        list-style: none;
        display: inline-flex;
    }

    .social-contact-form li a {
        display: inline-block;
    }

    .fb-icon {
        color: #000000;
        font-size: 20px;
    }

    .ig-icon {
        color: #000000;
        font-size: 20px;
    }

    .wp-icon {
        color: #000000;
        font-size: 20px;
    }

    .contact-form .contact-input::placeholder,
    .contact-form .contact-textarea::placeholder {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 15px;
    }
}

@media (320px <=width <=480px) {
    nav .mainMenu .closeMenu {
        display: block;
        position: absolute;
        top: 18px;
        right: 0 !important;
    }

    .container-text {
        z-index: 1;
        margin: auto;
        margin: 8px;
    }

    .container-text h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 2em;
        padding: 5px;
    }

    .container-text p {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 1.2em;
        color: #EEE;
    }

    .logo img {
        width: 16vh;
        margin-left: 0;
    }

    .grid-wrapper {
        background-color: white;
        width: 100%;
    }

    .grid-layout {
        grid-template-columns: auto;
    }

    .featured-h2 {
        font-family: 'STIHL-text-dem', sans-serif;
        font-weight: 600;
        margin: 10px;
        padding: 5px;
        margin-left: 20%;
        text-transform: uppercase;
        color: var(--mainColor);
        text-decoration: underline;
        letter-spacing: 3px;
        display: flex;
        margin-bottom: 30px;
        justify-content: center;
    }

    .title-featured {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 18px;
        text-decoration: none;
        color: var(--mainColor);
    }

    .p-featured {
        font-family: 'STIHL-text', sans-serif;
        font-size: 15px;
    }

    .catalogue {
        font-family: 'STIHL-text', sans-serif;
        font-size: 18px;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .catalogue-btn {
        font-size: 14px;
        padding: 15px 25px;
    }

    .grid-container {
        background-color: var(--mainColor);
        padding: 1em;
    }

    .wrapper-imgs {
        display: grid;
        justify-content: center;
        gap: 10px;
    }

    .grid-item {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .grid-item img {
        width: 100%;
        height: auto;
        margin: 5px;
        border-radius: 15px;
        background-color: var(--mainColor);
        opacity: 0.6;
    }

    .image-first-column {
        position: relative;
        left: 1%;
    }

    .image-second-column {
        position: relative;
        right: 1%;
    }

    .tips-stihl {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 25px;
        text-decoration: underline;
        margin-bottom: 20px;
    }

    .custom-prev-arrow,
    .custom-next-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        z-index: 1;
        font-size: 7px;
        color: #fff;
        overflow-y: hidden;
    }

    .custom-prev-arrow {
        left: 3vh;
    }

    .custom-next-arrow {
        right: 3vh;
    }

    .carousel-hover-text {
        letter-spacing: 0.8px;
        text-align: center;
        font-size: 9px;
        padding: 3px;
        margin: 1px;
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.3s;
    }

    .footer {
        background-color: #1f1e1e;
        padding: 20px;
    }

    .logo-footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo-footer img {
        width: 18vh;
    }

    .links-footer {
        display: flex;
        list-style: none;
        justify-content: center;
        margin-top: 30px;
    }

    .links-footer li a {
        font-size: 14px;
        color: #ffffff;
        text-decoration: none;
        transition: color 0.5s, font-size 0.5s;
    }

    .contact-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 10px;
        padding: 10px;
        margin-top: 40px;
        color: #ffffff;
    }

    .icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
    }

    .icon-contact {
        font-size: 21px;
    }

    .text-icons {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 13px;
        margin: 0 5px;
    }

    .text-number {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 13px;
        margin: 0 5px;
    }

    .social-network {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        gap: 20px;
        text-decoration: none;
        margin-top: 15%;
    }

    .social-icons {
        font-size: 25px;
        color: #ffffff;
    }

    .icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .faq-container {
        background-color: rgb(248, 223, 182);
        width: 90%;
        border-radius: 15px;
        background-image: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 7%;
        overflow: hidden;
    }

    .question {
        width: 100%;
        padding: 3px;
    }

    .question-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .question-header h4 {
        font-family: 'STIHL-text', sans-serif;
        font-size: 14px;
        cursor: pointer;
        margin-right: 10px;
        overflow: hidden;
    }

    .answer-text {
        font-size: 14px;
    }

    .contact-container-faq {
        font-size: 14px;
        position: relative;
        display: flex;
        text-align: center;
        align-self: center;
        width: 100%;
        margin: 0;
        padding: 10px;
        top: 0;
    }

    .contact-icons-faq {
        display: flex;
        align-self: center;
    }

    .contact-icons-faq a,
    .contact-icons-faq span {
        margin: 0 5px;
        display: inline-block;
    }

    .icon-container {
        display: flex;
        align-items: center;
    }

    .icons-container-faq {
        width: 100%;
    }

    .icons-faq {
        font-size: 15px;
        color: var(--mainColor);
        display: inline-block;
    }

    .wp-faq {
        font-size: 23px;
        margin-top: 3px;
    }

    .mbl {
        font-size: 15px;
        color: var(--mainColor);
        display: inline-block;
        margin-left: 5px;
    }

    .popup {
        font-family: 'STIHL-text-dem', sans-serif;
        font-size: 12px;
        top: -45px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        padding: 8px;
    }

    .text-number-faq {
        font-size: 12px;
    }

    .wrap-products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .animated-header {
        height: 25vh;
    }

    .search-bar-container {
        display: flex;
        position: absolute;
        justify-content: center;
        height: auto;
        margin: 0;
        top: 19.8%;
    }

    .search_bar .search_box {
        position: relative;
        width: 75vw;
        max-width: 300px;
        height: 5vh;
    }

    .search_bar .search_box .input-bar {
        font-family: 'STIHL-text-light', sans-serif;
        font-size: 0.7rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 0;
        outline: none;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 0.8rem;
    }

    .search_bar .search_box .btn {
        width: 40px;
        height: 100%;
    }

    .fa-xmark-bar {
        display: flex;
        justify-content: center;
        font-size: 1rem;
        margin-right: 1.9em;
        margin-bottom: 0;
    }

    .animated-imgs {
        display: none;
    }

    .ms-animated {
        display: none;
    }

    .categories-brands-list {
        position: absolute;
        margin-top: -4%;
        left: 0%;
        width: 47%;
        margin-left: 2.8%;
        background-color: #f4f4f4;
        padding: 5px;
        z-index: 1;
        display: none;
        box-shadow: 0 1px 7px rgba(51, 51, 51, .33);
    }

    .brands-column {
        margin-left: 49.8%;
    }

    .categories-brands-list h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 11px;
    }

    .categories-brands-list ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }

    .categories-brands-list.expanded ul {
        max-height: 200px;
    }

    .categories-brands-list li {
        margin-bottom: 10px;
    }

    .categories-brands-list a {
        font-size: 10px;
        text-decoration: none;
        color: #333;
        display: block;
        padding: 0;
        margin: 0;
        transition: background-color 0.2s ease-in-out;
    }

    .categories-brands-list a:hover {
        background-color: #ddd;
        border-radius: 5px;
        color: #222;
    }

    .categories-brands-list a.active {
        font-weight: bold;
        color: var(--mainColor);
        background-color: #ddd;
        border-radius: 2px;
    }

    .filters-products-container {
        min-height: 210vh;
    }

    .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
    }

    .filter-title:hover {
        color: var(--mainColor);
    }

    .filter-title-icon {
        display: none;
    }

    .filter-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .filter-button {
        font-family: 'STIHL-text', sans-serif;
        font-size: 12px;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        margin-top: 30px;
        border-radius: 8px;
        min-width: 70px;
        height: 50px;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        border: 1px solid rgb(136, 136, 136);
    }

    .arrow-button {
        font-size: 12px;
        margin-left: 25%;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    .overlay-list {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
        display: block;
    }

    .products-grid {
        width: 100% !important;
    }

    .loading-products {
        font-family: 'STIHL-text-dem', sans-serif;
        font-size: 17px;
        display: none;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 0;
        padding: 18px;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgba(51, 51, 51, .33);
        text-align: center;
        width: 90%;
        box-sizing: border-box;
        background-color: white;
        height: 470px;
    }

    .details-product-button {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 13px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 13px;
        text-align: center;
        text-decoration: none;
        border-radius: 5px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .product-image {
        max-width: 100%;
        height: 34vh;
        object-fit: contain;
    }

    .product-details-wrapper {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        width: 280px;
        height: 67vh;
        margin-top: 90px;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .product-image-detail {
        max-width: 45%;
        height: 15vh;
        margin-top: 65px;
        object-fit: contain;
    }

    .product-info {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        padding: 0px;
        width: 100%;
    }

    .product-name-detail {
        font-size: 13px;
        margin-bottom: 15px;
    }

    .product-description-detail {
        font-size: 12px;
        width: 90%;
        margin: 5px;
    }

    .spec-product {
        font-size: 12px;
        color: #000000;
        margin: 5px;
    }

    .details-single-product-button {
        border-radius: 4px;
        font-size: 8px;
        padding: 12px;
        margin: 10px;
    }

    .img-disclaimer {
        font-size: 11px;
        padding: 0;
        margin: 0;
        width: 250px;
    }

    .product-specification-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .full-description {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 21px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .product-specification {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 21px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .product-description-wrapper {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        background-color: #ffffff;
        width: 280px;
        margin: 0;
        margin-bottom: 40%;
        padding: 1.7rem;
        border-radius: 8px;
        box-shadow: 0 3px 6px #d1d1d1;
    }

    .product-specification-wrapper {
        font-size: 13px;
        width: 280px;
        margin: 0;
        padding: 18px;
        text-align: left;
    }

    .related-products-wrapper {
        min-width: 280px;
        padding: 1rem;
    }

    .related-products-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 20px;
        padding: 10px;
    }

    .responsive-card {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        margin: 15px;
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
        height: 300px;
    }

    .related-products-cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .responsive-card-img {
        max-width: 55%;
        height: 30vh;
        object-fit: contain;
    }

    .responsive-card-name {
        font-family: 'STIHL-text-bold', sans-serif;
        font-size: 12px;
        margin: 0;
        height: 25px;
    }

    .responsive-card-btn {
        font-family: 'STIHL-medium', sans-serif;
        font-size: 12px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        cursor: pointer;
        padding: 10px;
        margin: 5px;
        width: 70%;
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .contact-product-wrapper {
        font-family: 'STIHL-text', sans-serif;
        font-size: 14px;
        background-color: #ffffff;
        width: 280px;
        border-radius: 8px;
        padding: 0;
        margin: 0;
        box-shadow: 14px 14px 20px 0 rgba(20, 20, 20, 0.3);
    }

    .contact-title {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 19px;
        margin: 10px;
        margin-left: 6%;
        padding: 0;
    }

    .contact-subtitle {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 13px;
        padding: 0;
        margin: 10px;
        margin-left: 6%;
    }

    .contact-form {
        font-family: 'STIHL-text', sans-serif;
        font-size: 10px;
        margin: 18px;
        height: 50%;
        display: flex;
        flex-direction: column;
    }

    .contact-label {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .contact-input {
        font-size: 12px;
        padding: 5px;
        margin-bottom: 10px;
        border: 1px solid #afafaf;
        border-radius: 2px;
    }

    .contact-textarea {
        padding: 5px;
        margin-bottom: none;
        border: 1px solid #afafaf;
        border-radius: 6px;
        resize: none;
        height: 150px;
    }

    .contact-submit-button {
        font-size: 12px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        display: block;
        margin: 0;
        margin-top: 10px;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        transition-duration: 0.4s;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .social-contact-form {
        font-size: 11px;
        justify-content: flex-start;
        color: #0e0d0d;
        display: flex;
    }

    .social-contact-form li {
        list-style: none;
        display: inline-flex;
    }

    .social-contact-form li a {
        display: inline-block;
    }

    .fb-icon {
        color: #000000;
        font-size: 20px;
    }

    .ig-icon {
        color: #000000;
        font-size: 20px;
    }

    .wp-icon {
        color: #000000;
        font-size: 20px;
    }

    .contact-form .contact-input::placeholder,
    .contact-form .contact-textarea::placeholder {
        font-family: "STIHL-text-light", sans-serif;
        font-size: 15px;
    }
}

@media (max-width: 991px) {
    nav .mainMenu {
        height: 100vh;
        position: fixed;
        padding: 0;
        top: 0px;
        right: 0px;
        left: 0px;
        z-index: 10;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--mainColor);
        transition: top 1s ease 0s;
        display: none;
    }

    nav .mainMenu li a {
        display: inline-block;
        padding: 20px;
        text-decoration: none;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
        font-size: 18px;
        transition: 0.2s ease;
        font-weight: 300;
    }

    nav .mainMenu .closeMenu {
        display: block;
        position: absolute;
        top: 18px;
        right: 12px;
    }

    nav .openMenu {
        display: block;
        margin-right: 2%;
    }

    nav .mainMenu li a:hover {
        background: none;
        color: rgb(0, 0, 0);
    }

    .icons svg {
        font-size: 35px;
        display: inline-block;
        padding: 12px;
        color: #000000;
    }

    .filters-msg {
    font-family: 'STIHL-text-bold', sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    }
}

@media (max-width: 320px) {
    .container-text h2 {
        font-family: 'STIHL-titling', sans-serif;
        font-size: 1em;
        padding: 5px;
    }
}
